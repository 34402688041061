import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { IFeeMetricsData, LineDataSet } from '../../@types';
import { MetricsApi } from '../../lib';
import { getDate } from '../../util';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const TotalFeeMetric: React.FC = () =>  {
  const [totalL1Fees, setTotalL1Fees] = useState<IFeeMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const feeMetrics = await MetricsApi.getL1TotalFeeMetrics();
        setTotalL1Fees(feeMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    totalL1Fees && 'expanded'
  );

  const dataset: LineDataSet = {
    data: totalL1Fees && totalL1Fees.map(data => ({ date: getDate(data.date), 'Total L1 Fees': data.value })),
    xDataKey: 'date',
    yDataKey: 'Total L1 Fees',
  };

  const totalFeesOverSpan = totalL1Fees && totalL1Fees.map(data => data.value).reduce((a, b) => a + b, 0);

  return (
    <div className="panel flex-child">
      <div className={classes} >
        <PanelContent>
        {!totalL1Fees && !error && <Spinner />}
        {!totalL1Fees && error && <div className="error">{error}</div>}
        {totalL1Fees && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Total L1 Transaction Fees (Dragon)"
              min={0}
              dataSets={[dataset]}
            />
            Total L1 Transaction Fees (past {totalL1Fees.length} days): {totalFeesOverSpan && totalFeesOverSpan.toLocaleString()} Dragon
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TotalFeeMetric;
