import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { IEnergyMetricsData, LineDataSet, LineDataPoint } from '../../@types';
import { getDate } from '../../util';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const EnergyMetric: React.FC = () =>  {
  const [energy, setEnergy] = useState<IEnergyMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const energyMetrics = await MetricsApi.getEnergyMetrics();
        setEnergy(energyMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    energy && 'expanded'
  );

  const dataset: LineDataSet = {
    data: energy && energy.map((data): LineDataPoint => ({ date: getDate(data.date), 'Watts per Transaction': data.wattsPerTransaction })),
    xDataKey: 'date',
    yDataKey: 'Watts per Transaction',
  };

  const totalEnergyOverSpan = energy && energy.map(data => data.wattsPerTransaction * data.transactions).reduce((a, b) => a + b, 0) / 1000;
  const averageEnergyOverSpan = energy && totalEnergyOverSpan && totalEnergyOverSpan / (energy.length || 1);

  return (
    <div className="panel flex-child">
      <div className={classes} >
        <PanelContent>
        {!energy && !error && <Spinner />}
        {!energy && error && <div className="error">{error}</div>}
        {energy && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Energy Consumption"
              min={0}
              dataSets={[dataset]}
            />
            <div>Average Energy Consumption Per Day (past {energy.length} days): {averageEnergyOverSpan && averageEnergyOverSpan.toLocaleString()} KW</div>
            <div>Total Energy Consumption (past {energy.length} days): {totalEnergyOverSpan && totalEnergyOverSpan.toLocaleString()} KW</div>
            
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default EnergyMetric;
