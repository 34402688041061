import React, { useState, useEffect, ChangeEvent } from 'react';
import cx from 'classnames';
import { PanelContent, Title, Input } from '@dragonchain-dev/dragon-dashboard';
import { IEnergyMetricsData } from '../../@types';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import { intWithSeperator } from '../../util/helpers';

const EnergyCalculator: React.FC = () =>  {
  const LINODE_WATTS_PER_HOUR = 2.66
  const LINODE_NODES_PER_INSTANCE = 3

  const [energy, setEnergy] = useState<IEnergyMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [dailyTransactions, setDailyTransactions] = useState<number>(0);
  const [energyUsage, setEnergyUsage] = useState<number>(0);

  useEffect(() => {
    const fetch = async () => {
      try {
        const energyMetrics = await MetricsApi.getEnergyMetrics();
        setEnergy(energyMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    'height-100',
    energy && 'expanded'
  );

  const onEstimatedTransactionsChange = function(event:ChangeEvent){
    const element = event.currentTarget as HTMLInputElement
    let value = element.value === '' ? '0' : element.value;
    let estimatedTxs = Number(value.replace(/,+/g, ''));
    if (Number.isNaN(estimatedTxs) || estimatedTxs < 0 || !energy) {
      setEnergyUsage(0);
    }else {
      const kilowattsPerHour = LINODE_WATTS_PER_HOUR / 1000
      const kilowattsPerHourPerNode = kilowattsPerHour / LINODE_NODES_PER_INSTANCE
      const kilowattsPerDayPerNode = kilowattsPerHourPerNode * 24
      const networkTotalKWperDay = kilowattsPerDayPerNode * energy[energy.length - 1].nodes
      const averageTxsOverSpan = energy && energy.map(row => row.transactions).reduce((a, b) => a + b, 0) / (energy.length || 1);
      const averageEnergyUsagePerTransaction = networkTotalKWperDay / (averageTxsOverSpan + estimatedTxs) * 1000
      setDailyTransactions(estimatedTxs)
      setEnergyUsage(averageEnergyUsagePerTransaction);
    }
  }

  const formattedEnergyUsage = function(energy:number){
    let formattedValue = intWithSeperator(`${energy}`, { maxDecimals: 4 })
    if(energy > 0 && formattedValue === '0'){
      formattedValue = energy.toExponential(4)
    }
    return `${formattedValue} W/tx`
  }

  return (
    <div className="panel flex-child" id="energy-calculator">
      <div className={classes} >
        <PanelContent>
        {!energy && !error && <Spinner />}
        {!energy && error && <div className="error">{error}</div>}
        {energy && (
          <React.Fragment>
            <Title style={{ marginBottom: 10, fontWeight: 700 }} size="large" color="highlight">Energy Calculator</Title>

            <p>Using this tool your business can estimate your energy usage on the Dragonchain platform. Simply input your estimated daily transaction count, and it will return your approximate energy usage per transaction based on current network topology.</p>
            
            <table className="table" style={{ margin: '18px 0' }}>
              <tbody>
                  <tr>
                    <td className="cell">
                      <div className="number-display-panel">
                        <div className="inner">
                          <div className="title">
                            Daily TXs
                          </div>
                          <div className="value">
                            <Input
                              type="text"
                              value={intWithSeperator(`${dailyTransactions}`, { maxDecimals: 0 })}
                              onChange={onEstimatedTransactionsChange}
                              style={{margin:0}}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="cell">
                      <div className="number-display-panel">
                        <div className="inner">
                          <div className="title">Energy Usage</div>
                          <div className="value" >
                            <span style={{lineHeight:"21px", display:"block",padding:"15px 0"}}>{formattedEnergyUsage(energyUsage)}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>

            <p>Calculations are based on the current number of nodes per day at published rates and average daily transactions over the past month. For detailed information please see: <a href="https://dragonchain.com/blog/dragonchain-energy-efficiency-sustainability-report" target="_blank" rel="noopener noreferrer">Network Energy Efficiency and Sustainability Report.</a></p>
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default EnergyCalculator;
