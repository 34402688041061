import React from 'react';
import { Spinner as DragonSpinner } from '@dragonchain-dev/dragon-dashboard';
import { DimensionProps } from '../../@types';

const Spinner: React.FC<DimensionProps> = ({ width, height }) =>  {
  const styleOverrides = { margin: '0 auto', width: width || 100, height: height || 100}
  return <DragonSpinner style={styleOverrides} />;
};

export default Spinner;
