const configuration: any = {
  "production": {
    "METRICS_API": "https://metrics.api.dragonchain.com",
    "BILLING_API": "https://billing.api.dragonchain.com"
  },
  "staging": {
    "METRICS_API": "https://metrics-staging.api.dragonchain.com",
    "BILLING_API": "https://billing-staging.api.dragonchain.com"
  },
  "local": {
    "METRICS_API": "https://metrics-staging.api.dragonchain.com",
    "BILLING_API": "https://billing-staging.api.dragonchain.com"
  }
}

export default configuration;