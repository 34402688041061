import { BILLING_API } from './config';
import { ITakaraPriceData } from '../@types';

export default class BillingApi {
  static baseUrl = BILLING_API;
  static defaultOptions = { method: 'GET' };

  static async getCurrentTakaraPrice(): Promise<ITakaraPriceData[]> {
    const { metrics } = await BillingApi.get('/v3/prices/takara-history');
    return metrics;
  }

  static get(path: string) {
    return BillingApi.makeRequest(path);
  }

  static async makeRequest(path: string, options?: object) {
    const requestParams = { ...BillingApi.defaultOptions, ...options };

    const response = await fetch(`${BillingApi.baseUrl}${path}`, requestParams);
    if (!response.ok) throw new Error('Bad response from billing service.');

    return await response.json();
  }
}
