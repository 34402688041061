import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { BillingApi } from '../../lib';
import Spinner from '../Spinner';
import { ITakaraPriceData, LineDataSet } from '../../@types';
import { getFormattedMonth } from '../../util';
import LineChart from '../LineChart';

const TakaraPriceMetric: React.FC = () =>  {
  const [takaraPrice, setTakaraPrice] = useState<ITakaraPriceData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const prices = await BillingApi.getCurrentTakaraPrice();
        setTakaraPrice(prices);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    takaraPrice && 'expanded'
  );

  const dataset: LineDataSet = {
    data: takaraPrice && takaraPrice.map(data => ({ date: getFormattedMonth(data.effectiveDate), 'Takara Price': data.priceUsd })),
    xDataKey: 'date',
    yDataKey: 'Takara Price',
  };

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!takaraPrice && !error && <Spinner />}
        {!takaraPrice && error && <div className="error">{error}</div>}
        {takaraPrice && (
          <React.Fragment>
            <LineChart
              title="Takara Price (USD/Dragon)"
              min={0}
              dataSets={[dataset]}
            />
            Current Takara Price: ${takaraPrice[takaraPrice.length - 1].priceUsd.toFixed(4)} USD
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TakaraPriceMetric;
