export const getDate = (parsableDateValue: number | string) => {
  return (typeof parsableDateValue === 'number') ? new Date(parsableDateValue * 1000) : new Date(parsableDateValue);
};

export const getFormattedMonth = (parsableDateValue: number | string) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const date = (typeof parsableDateValue === 'number') ? new Date(parsableDateValue * 1000) : new Date(parsableDateValue);
  return months[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
};

export function abbreviateNumber(num: number, digits?: number): string {
  let units = ['K', 'M', 'B', 'T', 'Q'],
      decimal;

  for(let i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);

      if(num <= -decimal || num >= decimal) {
          return +(num / decimal).toFixed(digits) + units[i];
      }
  }

  return String(num);
}

export const isHex = (str: string) => (/^0[xX][0-9a-fA-F]+$/.test(str));

export const intWithSeperator = (str:string, {
  maxDecimals = 2,
  minDecimals = 0,
  style = undefined,
} = {}) => {
  if ((str === '') || isHex(str)) return str;
  const formattedNumber = new Intl.NumberFormat(
    'en', {
      maximumFractionDigits: maxDecimals || 2,
      minimumFractionDigits: minDecimals || 0,
      style,
      currency: style && 'USD',
    },
  ).format(+str);
  if (formattedNumber === 'NaN') return str;
  return formattedNumber;
};