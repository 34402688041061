import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { IGlobalTimeMetricsData, PieDataSet } from '../../@types';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import PieChart from '../PieChart';

const TotalTimeMetric: React.FC = () => {
  const [globalTime, setGlobalTime] = useState<IGlobalTimeMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const globalTimeMetrics = await MetricsApi.getGlobalTimeMetrics({ latest: true });
        setGlobalTime(globalTimeMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    globalTime && 'expanded'
  );

  const currentPoint = globalTime && globalTime[globalTime.length - 1];
  const applied = currentPoint && parseInt(currentPoint.applied);
  const total = currentPoint && parseInt(currentPoint.global);
  const unapplied = total && applied && total - applied;
  const data = (applied && unapplied && [unapplied, applied]) || []

  const pie: PieDataSet = { data, labels: ['Total Unapplied', 'Total Applied'] };

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!globalTime && !error && <Spinner />}
        {!globalTime && error && <div className="error">{error}</div>}
        {globalTime && (
          <React.Fragment>
            <PieChart
              title="Global TIME"
              dataSet={pie}
            />
            Current Total Time: {total && total.toLocaleString()}
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TotalTimeMetric;
