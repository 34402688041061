import React from 'react';
import ReactSVG from 'react-inlinesvg';
import logo from './logo.svg';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="contents">
        <a href="https://dragonchain.com">
          <ReactSVG width={200} src={logo} />
        </a>
        <div className="title">Metrics Dashboard</div>
      </div>
    </header>
  );
};

export default Header;

