import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { ITransactionMetricsData, LineDataSet, LineDataPoint } from '../../@types';
import { getDate } from '../../util';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const TransactionMetric: React.FC = () =>  {
  const [transactions, setTransactions] = useState<ITransactionMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const transactionMetrics = await MetricsApi.getTransactionMetrics();
        setTransactions(transactionMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    transactions && 'expanded'
  );

  const dataset: LineDataSet = {
    data: transactions && transactions.map((data): LineDataPoint => ({ date: getDate(data.date), 'Transactions': data.value })),
    xDataKey: 'date',
    yDataKey: 'Transactions',
  };

  const totalTransactionsOverSpan = transactions && transactions.map(data => data.value).reduce((a, b) => a + b, 0);
  const averageTransactionsOverSpan = transactions && totalTransactionsOverSpan && totalTransactionsOverSpan / (transactions.length || 1);

  return (
    <div className="panel flex-child">
      <div className={classes} >
        <PanelContent>
        {!transactions && !error && <Spinner />}
        {!transactions && error && <div className="error">{error}</div>}
        {transactions && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Transactions"
              min={0}
              dataSets={[dataset]}
            />
            <div>Average Transactions Per Day (past {transactions.length} days): {averageTransactionsOverSpan && averageTransactionsOverSpan.toLocaleString()}</div>
            <div>Total Transactions (past {transactions.length} days): {totalTransactionsOverSpan && totalTransactionsOverSpan.toLocaleString()}</div>
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TransactionMetric;
