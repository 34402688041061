import { METRICS_API } from './config';
import {
  ITimeMetricsData,
  INodeCountMeric,
  ITransactionMetricsData,
  ITotalRewardsMetricsData,
  IBlockMetricsData,
  IFeeMetricsData,
  IGlobalTimeMetricsData,
  IRewardPerTimeMetricsData,
  IEnergyMetricsData,
} from '../@types';

export default class MetricsApi {
  static baseUrl = METRICS_API;
  static defaultOptions = { method: 'GET' };

  static async getBlockMetrics(): Promise<IBlockMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/blocks');
    return metrics;
  }

  static async getTotalRewardsMetrics(): Promise<ITotalRewardsMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/total-transaction-rewards');
    return metrics;
  }

  static async getAppliedTimeMetrics(): Promise<ITimeMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/applied-time');
    return metrics;
  }

  static async getRewardPerTimeMetrics(): Promise<IRewardPerTimeMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/reward-per-time');
    return metrics;
  }

  static async getAverageFeePerBlockMetrics(): Promise<IFeeMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/block-fee');
    return metrics;
  }

  static async getGlobalTimeMetrics({ latest }: { latest: boolean }): Promise<IGlobalTimeMetricsData[]> {
    const url = latest ? '/v1/total-time-metrics?latest=true' : '/v1/total-time-metrics';
    const { metrics } = await MetricsApi.get(url);
    return metrics;
  }

  static async getNodeCountMetrics({ latest }: { latest: boolean }): Promise<INodeCountMeric[]> {
    const url = latest ? '/v1/node-count?latest=true' : '/v1/node-count'
    const { metrics } = await MetricsApi.get(url);
    return metrics;
  }

  static async getEnergyMetrics(): Promise<IEnergyMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/energy');
    return metrics;
  }

  static async getTransactionMetrics(): Promise<ITransactionMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/transactions');
    return metrics;
  }

  static async getL1AverageFeeMetrics(): Promise<IFeeMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/transaction-fee');
    return metrics;
  }

  static async getL1TotalFeeMetrics(): Promise<IFeeMetricsData[]> {
    const { metrics } = await MetricsApi.get('/v1/total-transaction-fees');
    return metrics;
  }

  static get(path: string) {
    return MetricsApi.makeRequest(path);
  }

  static async makeRequest(path: string, options?: object) {
    const requestParams = { ...MetricsApi.defaultOptions, ...options };

    const response = await fetch(`${MetricsApi.baseUrl}${path}`, requestParams);
    if (!response.ok) throw new Error('Bad response from metrics service.');

    return await response.json();
  }
}
