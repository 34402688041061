import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { IBlockMetricsData, LineDataSet } from '../../@types';
import { getDate } from '../../util';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const BlockMetric: React.FC = () =>  {
  const [blocks, setBlocks] = useState<IBlockMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const blockMetrics = await MetricsApi.getBlockMetrics();
        setBlocks(blockMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    blocks && 'expanded'
  );

  const dataset: LineDataSet = {
    data: blocks && blocks.map(data => ({ date: getDate(data.date), 'Blocks': data.value })),
    xDataKey: 'date',
    yDataKey: 'Blocks',
  };

  const totalBlocksOverSpan = blocks && blocks.map(data => data.value).reduce((a, b) => a + b, 0);
  const averageBlocksOverSpan = blocks && totalBlocksOverSpan && totalBlocksOverSpan / (blocks.length || 1);

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!blocks && !error && <Spinner />}
        {!blocks && error && <div className="error">{error}</div>}
        {blocks && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Blocks"
              min={0}
              dataSets={[dataset]}
            />
            <div>Average Blocks Per Day (past {blocks.length} days): {averageBlocksOverSpan && averageBlocksOverSpan.toLocaleString()}</div>
            <div>Total Blocks (past {blocks.length} days): {totalBlocksOverSpan && totalBlocksOverSpan.toLocaleString()}</div>
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default BlockMetric;
