import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { IFeeMetricsData, LineDataSet } from '../../@types';
import { getDate } from '../../util';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const AverageBlockFeeMetric: React.FC = () =>  {
  const [averageBlockFees, setAverageBlockFees] = useState<IFeeMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const blockFeeMetrics = await MetricsApi.getAverageFeePerBlockMetrics();
        setAverageBlockFees(blockFeeMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    averageBlockFees && 'expanded'
  );

  const dataset: LineDataSet = {
    data: averageBlockFees && averageBlockFees.map(data => ({ date: getDate(data.date), 'Average L1 Fee': data.value })),
    xDataKey: 'date',
    yDataKey: 'Average L1 Fee',
  };

  const totalFeesOverSpan = averageBlockFees && averageBlockFees.map(data => data.value).reduce((a, b) => a + b, 0);
  const averageFeeOverSpan = averageBlockFees && totalFeesOverSpan && totalFeesOverSpan / (averageBlockFees.length || 1);
  const averageFeeOverSpanDisplay = averageFeeOverSpan && averageFeeOverSpan.toFixed(4);

  return (
    <div className="panel flex-child">
      <div className={classes} >
        <PanelContent>
        {!averageBlockFees && !error && <Spinner />}
        {!averageBlockFees && error && <div className="error">{error}</div>}
        {averageBlockFees && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Average Fee Per Block (Dragon)"
              min={0}
              dataSets={[dataset]}
            />
            Average Fee Per Block (past {averageBlockFees.length} days): {averageFeeOverSpanDisplay && averageFeeOverSpanDisplay.toLocaleString()} Dragon
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default AverageBlockFeeMetric;
