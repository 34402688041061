import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent, Title, NumberDisplayPanel } from '@dragonchain-dev/dragon-dashboard';
import { INodeCountMeric } from '../../@types';
import { MetricsApi } from '../../lib';
import Spinner from '../Spinner';

const calculateSelectionChance = (numberOfNodes: number, numberOfNodesSelected: number) => {
  if (numberOfNodesSelected > numberOfNodes) return 1; // This should basically never happen (i.e. only 2 level 2s registered even though we select 3)
  let cumulative = 1;
  for (let i = numberOfNodesSelected; i > 0; i--) {
    cumulative *= (numberOfNodes - i) / (numberOfNodes - (i - 1))
  }
  // Cumulative is the chance to NOT be selected, so we have to subtract it from 100% to get the chance of _getting_ selected
  return 1 - cumulative;
}

const NodeCountMetric: React.FC = () => {
  const [nodeCount, setNodeCount] = useState<INodeCountMeric[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const nodeCounts = await MetricsApi.getNodeCountMetrics({ latest: true });
        setNodeCount(nodeCounts);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    'height-100',
    nodeCount && 'expanded'
  );

  const currentPoint = nodeCount && nodeCount[nodeCount.length - 1];
  const currentL2Chance = (calculateSelectionChance((currentPoint && currentPoint.l2) || 0, 3) * 100).toLocaleString();;
  const currentL3Chance = (calculateSelectionChance((currentPoint && currentPoint.l3) || 0, 1) * 100).toLocaleString();;
  const currentL4Chance = (calculateSelectionChance((currentPoint && currentPoint.l4) || 0, 1) * 100).toLocaleString();;
  const currentL5Chance = (calculateSelectionChance((currentPoint && currentPoint.l5) || 0, 1) * 100).toLocaleString();;

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!nodeCount && !error && <Spinner />}
        {!nodeCount && error && <div className="error">{error}</div>}
        {nodeCount && (
          <React.Fragment>
            <Title style={{ marginBottom: 10, fontWeight: 700 }} size="large" color="highlight">Dragon Net Chance of Selection Per Block</Title>
            <table className="table">
              <tbody>
                <tr>
                  <td className="cell"><NumberDisplayPanel title="L2" value={`${currentL2Chance}%`} /></td>
                  <td className="cell"><NumberDisplayPanel title="L3" value={`${currentL3Chance}%`} /></td>
                </tr>
                <tr>
                  <td className="cell"><NumberDisplayPanel title="L4" value={`${currentL4Chance}%`} /></td>
                  <td className="cell"><NumberDisplayPanel title="L5" value={`${currentL5Chance}%`} /></td>
                </tr>
              </tbody>
            </table>
            <p>
              The chance of selection for verification is completely random between all the nodes on that level.
            </p>
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default NodeCountMetric;
