import React from 'react';
import Welcome from './components/Welcome';
import Metrics from './components/Metrics';
import Header from './components/Header';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <div className="contents">
        <Welcome />
        <Metrics />
      </div>
    </div>
  );
}

export default App;
