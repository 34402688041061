import React from 'react';
import { Panel, PanelContent, PanelRow, Title, Actions, LinkToButton } from '@dragonchain-dev/dragon-dashboard';

const Welcome: React.FC = () => {
  return (
    <Panel>
      <PanelRow>
        <PanelContent icon="network">
          <Title size="small" color="highlight">Welcome to the Dragon Net Metrics Dashboard!</Title>
          <p>
            If you are interested in creating managed or on premises blockchain nodes that participate in Dragon Net,
            click the button below to sign up for a Dragonchain account.
          </p>
        </PanelContent>
        <Actions>
          <LinkToButton next type="link" target="_blank" rel="noopener noreferrer" to="https://account.dragonchain.com">Register Now</LinkToButton>
        </Actions>
      </PanelRow>
    </Panel>
  )
};

export default Welcome;
