import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { ITimeMetricsData, LineDataSet } from '../../@types';
import { MetricsApi } from '../../lib';
import { getDate } from '../../util';
import Spinner from '../Spinner';
import LineGraph from '../LineChart';

const TimeMetric: React.FC = () => {
  const [appliedTime, setAppliedTime] = useState<ITimeMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const appliedTimeMetrics = await MetricsApi.getAppliedTimeMetrics();
        setAppliedTime(appliedTimeMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    appliedTime && 'expanded'
  );

  const l1: LineDataSet = { data: appliedTime && appliedTime.map(data => ({ date: getDate(data.date), 'L1 TIME': data.l1 })), xDataKey: 'date', yDataKey: 'L1 TIME' };
  const l2: LineDataSet = { data: appliedTime && appliedTime.map(data => ({ date: getDate(data.date), 'L2 TIME': data.l2 })), xDataKey: 'date', yDataKey: 'L2 TIME' };
  const l3: LineDataSet = { data: appliedTime && appliedTime.map(data => ({ date: getDate(data.date), 'L3 TIME': data.l3 })), xDataKey: 'date', yDataKey: 'L3 TIME' };
  const l4: LineDataSet = { data: appliedTime && appliedTime.map(data => ({ date: getDate(data.date), 'L4 TIME': data.l4 })), xDataKey: 'date', yDataKey: 'L4 TIME' };
  const l5: LineDataSet = { data: appliedTime && appliedTime.map(data => ({ date: getDate(data.date), 'L5 TIME': data.l5 })), xDataKey: 'date', yDataKey: 'L5 TIME' };

  const totalAppliedTimeOverSpan = appliedTime && appliedTime.map(data => parseInt(data.l1) + parseInt(data.l2) + parseInt(data.l3) + parseInt(data.l4) + parseInt(data.l5)).reduce((a, b) => (a + b), 0);
  const averageAppliedTimeOverSpan = appliedTime && totalAppliedTimeOverSpan && totalAppliedTimeOverSpan / ((appliedTime && appliedTime.length * 5) || 1);

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!appliedTime && !error && <Spinner />}
        {!appliedTime && error && <div className="error">{error}</div>}
        {appliedTime && (
          <React.Fragment>
            <LineGraph
              title="Average Applied TIME Per Node"
              min={0}
              xAxisType="time"
              dataSets={[l1, l2, l3, l4, l5]}
            />
            Average Applied Time Per Node (all levels, past {appliedTime.length} days): {averageAppliedTimeOverSpan && averageAppliedTimeOverSpan.toLocaleString()}
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TimeMetric;
