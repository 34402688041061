import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, ChartOptions } from 'chart.js';
import { Title } from '@dragonchain-dev/dragon-dashboard';
import { LineChartProps } from '../../@types';
import { abbreviateNumber } from '../../util';

// Warning: chart.js and react-chartjs-2 don't have very good type bindings.
// This lead me to have to hack in some type casting stuff in this file.
// It's not pretty but it does the job!

// HACK! There is no exposed config for adding padding
// between the legend and chart. This does the trick!
(Chart as any).Legend.prototype.afterFit = function () {
  this.height = this.height + 20;
};

// Currently supporting only 5 data sets. If we want additional ones, add new colors to the end of this list.
const colors = ['#1797e5', '#f1261f', '#3e9651', '#6b4c9a', '#da7c30']

const LineChart = ({
  title,
  dataSets,
  xAxisType,
  min = undefined,
  max = undefined,
  stepSize = undefined,
  tooltips = undefined,
}: LineChartProps) => {
  const graphData: Chart.ChartData = {
    labels: (dataSets && dataSets[0] && dataSets[0].data && dataSets[0].data.map(datum => datum[dataSets[0].xDataKey]) as Array<string>) || [],
    datasets: dataSets.map((dataSet, index): Chart.ChartDataSets => {
      return {
        label: dataSet.yDataKey,
        fill: false,
        lineTension: 0.2,
        backgroundColor: dataSet.lineStroke || colors[index],
        borderColor: dataSet.lineStroke || colors[index],
        pointBorderColor: dataSet.lineStroke || colors[index],
        pointHoverBorderColor: dataSet.lineStroke || colors[index],
        pointHoverBackgroundColor: dataSet.lineStroke || colors[index],
        borderCapStyle: 'butt',
        borderJoinStyle: 'miter',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: (dataSet && dataSet.data && dataSet.data.map(datum => datum[dataSet.yDataKey]) as Array<number>) || [],
      };
    }),
  };

  const options: ChartOptions = {
    scales: {
      yAxes: [{
        position: 'left',
        type: 'linear',
        ticks: {
          stepSize,
          min,
          max,
          callback: (val: number) => abbreviateNumber(val, 1),
        },
      }],
      // This will cause the X axis scale to be correct for dates
      xAxes: (xAxisType === 'time' ? [{ type: 'time', distribution: 'linear' }] : []),
    },
    tooltips: tooltips === undefined ? {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toLocaleString();
        },
      },
    } : tooltips,
  };

  return (
    <React.Fragment>
      <Title style={{ marginBottom: 10, fontWeight: 700 }} size="large" color="highlight">{title}</Title>
      <Line data={graphData} options={options} />
    </React.Fragment>
  );
};

export default LineChart;
