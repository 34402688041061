import React from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { Title } from '@dragonchain-dev/dragon-dashboard';
import { PieGraphProps } from '../../@types';

// Warning: chart.js and react-chartjs-2 don't have very good type bindings.
// This lead me to have to hack in some type casting stuff in this file.
// It's not pretty but it does the job!

const colors = ['#1797e5', '#f1261f', '#3e9651', '#6b4c9a', '#da7c30']

const PieChart = ({
  title,
  dataSet,
}: PieGraphProps) => {
  const graphData: Chart.ChartData = {
    labels: (dataSet && dataSet.labels) || [],
    datasets: [{
      backgroundColor: colors,
      borderColor: colors,
      hoverBackgroundColor: colors,
      data: (dataSet && dataSet.data) || [],
      borderWidth: 1,
    }],
  };

  const options: ChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return data.labels[tooltipItem.index] + ': ' + data.datasets[0].data[tooltipItem.index].toLocaleString();
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Title style={{ marginBottom: 10, fontWeight: 700 }} size="large" color="highlight">{title}</Title>
      <Pie data={graphData} options={options} />
    </React.Fragment>
  );
};

export default PieChart;
