import React from 'react';
import TakaraPriceMetric from '../TakaraPriceMetric';
import BlockMetric from '../BlockMetric';
import TransactionMetric from '../TransactionMetric';
import AverageFeeMetric from '../AverageFeeMetric';
import TotalFeeMetric from '../TotalFeeMetric';
import TimeMetric from '../TimeMetric';
import NodeCountMetric from '../NodeCountMetric';
import TotalRewardsMetric from '../TotalRewardsMetric';
import TotalTimeMetric from '../TotalTimeMetric';
import AverageBlockFeeMetric from '../AverageBlockFeeMetric';
import AverageRewardPerTimeMetric from '../AverageRewardPerTimeMetric';
import SelectionChanceMetric from '../SelectionChanceMetric';
import EnergyMetric from '../EnergyMetric';
import EnergyCalculator from '../EnergyCalculator';

const Metrics: React.FC = () =>  {
  return (
    <React.Fragment>
      <div className="graph-container">
        <TransactionMetric />
        <BlockMetric />
        <NodeCountMetric />
        <TimeMetric />
        <TotalTimeMetric />
        <TotalRewardsMetric />
        <TotalFeeMetric />
        <AverageRewardPerTimeMetric />
        <AverageFeeMetric />
        <AverageBlockFeeMetric />
        <SelectionChanceMetric />
        <TakaraPriceMetric />
        <EnergyMetric />
        <EnergyCalculator />
      </div>
    </React.Fragment>
  );
};

export default Metrics;
