import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { ITotalRewardsMetricsData, LineDataSet } from '../../@types';
import { MetricsApi } from '../../lib';
import { getDate } from '../../util';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const TotalRewardsMetric: React.FC = () => {
  const [totalRewards, setTotalRewards] = useState<ITotalRewardsMetricsData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const totalRewardsMetrics = await MetricsApi.getTotalRewardsMetrics();
        setTotalRewards(totalRewardsMetrics);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    totalRewards && 'expanded'
  );

  const l2: LineDataSet = { data: totalRewards && totalRewards.map(data => ({ date: getDate(data.date), 'L2 Rewards': data.l2 })), xDataKey: 'date', yDataKey: 'L2 Rewards', lineStroke: '#f1261f' };
  const l3: LineDataSet = { data: totalRewards && totalRewards.map(data => ({ date: getDate(data.date), 'L3 Rewards': data.l3 })), xDataKey: 'date', yDataKey: 'L3 Rewards', lineStroke: '#3e9651' };
  const l4: LineDataSet = { data: totalRewards && totalRewards.map(data => ({ date: getDate(data.date), 'L4 Rewards': data.l4 })), xDataKey: 'date', yDataKey: 'L4 Rewards', lineStroke: '#6b4c9a' };
  const l5: LineDataSet = { data: totalRewards && totalRewards.map(data => ({ date: getDate(data.date), 'L5 Rewards': data.l5 })), xDataKey: 'date', yDataKey: 'L5 Rewards', lineStroke: '#da7c30' };

  const totalRewardsOverSpan = totalRewards && totalRewards.map(data => parseInt(data.l2) + parseInt(data.l3) + parseInt(data.l4) + parseInt(data.l5)).reduce((a, b) => (a + b), 0);

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!totalRewards && !error && <Spinner />}
        {!totalRewards && error && <div className="error">{error}</div>}
        {totalRewards && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Total Rewards Per Level"
              min={0}
              dataSets={[l2, l3, l4, l5]}
            />
            Total Rewards (past {totalRewards.length} days): {totalRewardsOverSpan && totalRewardsOverSpan.toLocaleString()} Dragon
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default TotalRewardsMetric;
