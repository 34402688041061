import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { PanelContent } from '@dragonchain-dev/dragon-dashboard';
import { INodeCountMeric, LineDataSet } from '../../@types';
import { MetricsApi } from '../../lib';
import { getDate } from '../../util';
import Spinner from '../Spinner';
import LineChart from '../LineChart';

const NodeCountMetric: React.FC = () => {
  const [nodeCount, setNodeCount] = useState<INodeCountMeric[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const nodeCounts = await MetricsApi.getNodeCountMetrics({ latest: false });
        setNodeCount(nodeCounts);
      } catch (e) {
        setError('Error fetching this metric. Please refresh to try again.')
      }
    };
    fetch();
  }, []);

  const classes = cx(
    'graph-content',
    'inner',
    nodeCount && 'expanded'
  );

  const l1: LineDataSet = { data: nodeCount && nodeCount.map(data => ({ date: getDate(data.date), 'L1': data.l1 })), xDataKey: 'date', yDataKey: 'L1' };
  const l2: LineDataSet = { data: nodeCount && nodeCount.map(data => ({ date: getDate(data.date), 'L2': data.l2 })), xDataKey: 'date', yDataKey: 'L2' };
  const l3: LineDataSet = { data: nodeCount && nodeCount.map(data => ({ date: getDate(data.date), 'L3': data.l3 })), xDataKey: 'date', yDataKey: 'L3' };
  const l4: LineDataSet = { data: nodeCount && nodeCount.map(data => ({ date: getDate(data.date), 'L4': data.l4 })), xDataKey: 'date', yDataKey: 'L4' };
  const l5: LineDataSet = { data: nodeCount && nodeCount.map(data => ({ date: getDate(data.date), 'L5': data.l5 })), xDataKey: 'date', yDataKey: 'L5' };

  const currentPoint = nodeCount && nodeCount[nodeCount.length - 1];
  const currentTotalNodeCount = ((currentPoint && currentPoint.l1) || 0) + ((currentPoint && currentPoint.l2) || 0) + ((currentPoint && currentPoint.l3) || 0) + ((currentPoint && currentPoint.l4) || 0) + ((currentPoint && currentPoint.l5) || 0);

  return (
    <div className="panel flex-child">
      <div className={classes}>
        <PanelContent>
        {!nodeCount && !error && <Spinner />}
        {!nodeCount && error && <div className="error">{error}</div>}
        {nodeCount && (
          <React.Fragment>
            <LineChart
              xAxisType="time"
              title="Chains"
              min={0}
              dataSets={[l1, l2, l3, l4, l5]}
              tooltips={{
                mode: 'x-axis',
                callbacks: {
                  label: (tooltipItem: any, data: any) => {
                    return `${data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toLocaleString()}`;
                  },
                  footer: (items: any, data: any) => {
                    const tooltipItem = items[0]
                    const point = nodeCount && nodeCount[tooltipItem.index];
                    const totalNodeCount = ((point && point.l1) || 0) + ((point && point.l2) || 0) + ((point && point.l3) || 0) + ((point && point.l4) || 0) + ((point && point.l5) || 0);
                    return `Total: ${totalNodeCount}`;
                  },
                },
              }}
            />
            Current Total Chains: {currentTotalNodeCount && currentTotalNodeCount.toLocaleString()}
          </React.Fragment>
        )}
        </PanelContent>
      </div>
    </div>
  );
};

export default NodeCountMetric;
